import { MappedEvent } from './Calendar'
import { EventsList } from './EventsList'
import { Icon, Popover, Text } from '@nike/eds'
import { MouseEvent } from 'react'
import { DayProps } from 'react-day-picker'

interface DayCellProps extends DayProps {
  isSelected: boolean
  events: MappedEvent[]
  resetSelection: (event: MouseEvent<HTMLElement, MouseEvent>) => void
  isExpanded?: boolean
}

export const DayCell = ({
  isSelected,
  events,
  resetSelection,
  isExpanded,
  ...rest
}: DayCellProps) => (
  <td>
    <Popover
      placement='top'
      isOpen={isSelected}
      // expect error flag is necessary because of the incorrect types declaration in eds
      //@ts-expect-error
      onClickOutside={(event: MouseEvent<HTMLElement, MouseEvent>) => {
        resetSelection(event)
      }}
      strategy={isExpanded ? 'fixed' : 'absolute'}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom', 'right', 'left'],
          },
        },
      ]}
      headerSlot={
        events.length ? (
          <>
            <div className='eds-flex eds-gap--16 eds-flex--align-items-center'>
              <Icon
                name='Calendar'
                color='white'
                backgroundShape='circle'
                backgroundColor='black'
                size='s'
              />
              <Text font='title-6'>Events</Text>
            </div>
            <Text font='legal-1' color='secondary' className='eds-spacing--mt-8'>
              All dates are displayed in your local timezone
            </Text>
          </>
        ) : null
      }
      bodySlot={<EventsList events={events} />}
    >
      <div {...rest} />
    </Popover>
  </td>
)
