import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { TabGroup, Tab } from '@nike/eds'

import { environments } from '../enums.js'
import { toggleEnvironment } from '../navigation/actions.js'

const options = [
  { value: 'prod', label: environments.prod },
  { value: 'preprod', label: environments.preprod },
]

function changeEnvironment(isProduction, path, navigate) {
  let resetPath = path.replace('/preprod', '')
  let newPath = isProduction ? resetPath : '/preprod' + resetPath
  newPath = !isProduction && path === '/' ? newPath + 'metrics' : newPath

  toggleEnvironment()
  navigate(`${newPath}`)
}

export default function EnvSwitch({ isProduction }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeId = isProduction ? 'prod' : 'preprod'
  return (
    <div className={'container-pad'}>
      <TabGroup
        name='env-select'
        activeId={activeId}
        onChange={(e) => {
          const isProd = e.target.id === 'prod'
          changeEnvironment(isProd, pathname, navigate)
        }}
        variant='toggle'
      >
        {options.map((tab) => (
          <Tab key={tab} id={tab.value}>
            {tab.label}
          </Tab>
        ))}
      </TabGroup>
    </div>
  )
}
