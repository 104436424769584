import React, { Fragment, useEffect } from 'react'
import { useAuth, ExpiredTokenNotification } from '@nike/aegis-auth-react'
import { useDispatch } from 'react-redux'

import Toasts from '../notifications/Toasts.js'
import { LoginRequired } from '../auth/index.js'
import IntegratedPlatformWrapped from './IntegratedWrapper.js'
import OfflineBanner from '../offline/offline.js'
import { Container, PageSpinner } from '../components/index.js'
import { toggleEnvironment } from '../navigation/actions.js'
import AppRoutes from './AppRoutes.js'
import { client } from '../auth/client.js'
import { useLocation } from 'react-router-dom'

export default function App() {
  const dispatch = useDispatch()
  const authState = useAuth(client)
  const { pathname } = useLocation()
  const { loginComplete } = authState

  useEffect(() => {
    dispatch(toggleEnvironment)
  })

  if (!loginComplete) {
    return <PageSpinner />
  }

  const isHome = pathname === '/'
  const WrapperElement = isHome ? Fragment : Container

  return (
    <>
      <IntegratedPlatformWrapped>
        <OfflineBanner />
        <LoginRequired>
          <div className={isHome ? '' : 'app-container'}>
            <WrapperElement>
              <AppRoutes />
            </WrapperElement>
          </div>
        </LoginRequired>
      </IntegratedPlatformWrapped>
      <Toasts />
      <ExpiredTokenNotification storageNamespace='entitlements' />
    </>
  )
}
