import { CalendarEvent, EventType } from '../../../util/api/pc'
import { DayCell } from './DayCell'
import { Legend } from './Legend'
import { UpcomingEvents } from './UpcomingEvents'
import styles from './calendar.module.styl'
import { useState, MouseEvent } from 'react'
import { DayPicker, DayProps } from 'react-day-picker'
import 'react-day-picker/src/style.css'

interface CalendarProps {
  events: CalendarEvent[]
}

export interface MappedEvent {
  id: string
  title: string
  start: Date
  end: Date
  physicalLocation?: string
  virtualLocation?: string
  type: EventType
  description: string
}

export const Calendar = ({ events }: CalendarProps): JSX.Element => {
  const [selected, setSelected] = useState<Date>()

  const mappedEvents =
    events.map((item) => ({
      ...item,
      start: new Date(item.start),
      end: item?.end ? new Date(item.end) : new Date(item.start),
      physicalLocation: item?.location,
      virtualLocation: item?.url,
    })) || []

  const resetSelection = (event: MouseEvent<HTMLElement, MouseEvent>) => {
    const { classList } = event.target as HTMLElement
    if (!classList.contains('rdp-day_button')) {
      setSelected(undefined)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.calendarWrapper}>
        <DayPicker
          mode='single'
          selected={selected}
          onSelect={setSelected}
          components={{
            Day: (props: DayProps) => (
              <DayCell
                {...props}
                isSelected={selected?.toDateString() === props.day.date.toDateString()}
                events={mappedEvents.filter(
                  (event) => event.start.toDateString() === props.day.date.toDateString()
                )}
                resetSelection={resetSelection}
              />
            ),
          }}
          modifiers={{
            meetings: mappedEvents
              .filter((event) => event.type === EventType.MEETING)
              .map((event) => event.start),
            trainings: mappedEvents
              .filter((event) => event.type === EventType.TRAINING)
              .map((event) => event.start),
            releases: mappedEvents
              .filter((event) => event.type === EventType.RELEASE)
              .map((event) => event.start),
            others: mappedEvents
              .filter((event) => event.type === EventType.OTHER)
              .map((event) => event.start),
          }}
          modifiersClassNames={{
            meetings: styles.meetingEvent,
            trainings: styles.trainingEvent,
            releases: styles.releaseEvent,
            others: styles.otherEvent,
            selected: styles.selected,
            today: styles.today,
          }}
        />
        <Legend />
      </div>
      <UpcomingEvents events={mappedEvents} />
    </div>
  )
}
