import { SnackbarContext } from '../../Snack/Snack'
import { TrainingResource } from '../../util/api/pc'
import styles from './resourceTable.module.styl'
import { Table, TableHeading, TableCell, Text, Link, IconButton } from '@nike/eds'
import { useContext, useState } from 'react'

export interface TrainingResourceWithName extends TrainingResource {
  techSolutionName: string
}

interface TrainingResourcesProps {
  trainingResources?: TrainingResourceWithName[]
  isPlatformPage?: boolean
}

export const TrainingResources = ({
  trainingResources = [],
  isPlatformPage,
}: TrainingResourcesProps): JSX.Element => {
  const headers = ['Name', 'Description', 'Copy Link']
  if (isPlatformPage) headers.unshift('Tech Solution')
  return (
    <Table className={styles.resourcesTable}>
      <thead>
        <tr>
          {headers.map((header) => (
            <TableHeading key={header}>{header}</TableHeading>
          ))}
        </tr>
      </thead>
      <tbody>
        {trainingResources.map((resource) => (
          <tr key={resource.name}>
            {isPlatformPage && <TableCell>{resource.techSolutionName}</TableCell>}
            <LinkCell href={resource.url} displayName={resource.name} />
            <DescriptionCell description={resource.description} />
            <CopyCell href={resource.url} />
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export const CopyCell = ({ href }: { href: string }) => {
  const { createSnack } = useContext(SnackbarContext)
  const [copySuccess, setCopySuccess] = useState(false)

  async function copyLink() {
    createSnack({
      message: 'Link copied to clipboard',
      status: 'success',
      id: 'tr-link-copy',
    })

    await navigator.clipboard.writeText(href)
    setCopySuccess(true)
    setTimeout(() => {
      setCopySuccess(false)
    }, 2000)
  }

  return (
    <TableCell className={styles.copyCell}>
      <IconButton
        className={copySuccess ? styles.copyIcon : ''}
        onClick={copyLink}
        label='Copy Link'
        icon={copySuccess ? 'Check' : 'CopyPaste'}
        size='small'
        variant='ghost'
      />
    </TableCell>
  )
}

export const DescriptionCell = ({ description }: { description: string }) => (
  <TableCell>
    <Text font={'body-3'}>{description}</Text>
  </TableCell>
)

export const LinkCell = ({ href, displayName }: { href: string; displayName?: string }) => (
  <TableCell>
    <div className={styles.linkCell}>
      <Text<'span'> font='body-2' as='span'>
        <Link href={href} rel='noopener nofrerer' target='blank'>
          {displayName || href}
        </Link>
      </Text>
    </div>
  </TableCell>
)
