import { labels } from '../../constants'
import { Widget, ColorPalette, WidgetSize, WidgetLifecycleState } from '../../types'
import styles from '../../widget.module.styl'
import { ChartResolver, ChartData, ChartOptions } from '../Charts'
import { DisabledWidget } from '../DisabledWidget/DisabledWidget'
import { WidgetMessage } from '../WidgetMessage/WidgetMessage'
import { IconButton, Spinner } from '@nike/eds'
import classnames from 'classnames'

interface WidgetContentProps<T> {
  widget: T & { isFilteredOut?: boolean }
  isFetching: boolean
  chartOptions?: ChartOptions
  errorMessage?: string
  widgetData?: ChartData
  widgetSize?: WidgetSize
  isResponsive?: boolean
  getRefreshedWidgetData?: () => Promise<void>
  isExpanded?: boolean
}

export const WidgetContent = <T extends Widget>({
  widget,
  isFetching,
  chartOptions,
  errorMessage,
  widgetData,
  isResponsive = false,
  widgetSize = WidgetSize.SMALLSQUARE,
  isExpanded,
  getRefreshedWidgetData,
}: WidgetContentProps<T>) => {
  const hasWidgetData = !!widgetData?.value.data.length

  if (
    widget.lifecycle.state === WidgetLifecycleState.RETIRED ||
    widget.lifecycle.state === WidgetLifecycleState.DEPRECATED
  ) {
    return (
      <div
        className={classnames(styles.disabledContainer, {
          [styles.responsive]: isResponsive,
        })}
      >
        <DisabledWidget widget={widget} widgetSize={widgetSize} chartOptions={chartOptions} />
      </div>
    )
  }

  if (isFetching && !hasWidgetData) {
    return (
      <div className={styles.spinner}>
        <Spinner role='progressbar' />
      </div>
    )
  }

  if (errorMessage) {
    return <WidgetMessage text={errorMessage} />
  }

  if (hasWidgetData) {
    return (
      <ChartResolver
        type={widget.chartType || widgetData.value.type}
        data={widgetData.value?.data}
        columns={widgetData.value?.columns}
        additionalClassName={styles.chart}
        colorPalette={widget?.colorPalette || ColorPalette.CATEGORICAL}
        options={chartOptions}
        widgetSize={widgetSize}
        isResponsive={isResponsive}
        responsiveHeight={isExpanded ? '100%' : undefined}
        size={isExpanded ? 'large' : undefined}
        isExpanded={isExpanded}
      />
    )
  }

  return (
    <WidgetMessage
      text={labels.noDataMsg}
      additionalClassName={styles.notAvailable}
      icon={
        <IconButton
          icon='Repeat'
          label={labels.noDataRetry}
          size='small'
          variant='secondary'
          onClick={() => getRefreshedWidgetData?.()}
        />
      }
    />
  )
}
