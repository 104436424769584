import { ReleaseNoteMarkdown } from './ReleaseNoteMarkdown'
import styles from './releaseNotes.module.styl'
import { Text } from '@nike/eds'
import type { ReleaseNote } from 'util/api/pc'

interface ReleaseNoteBodyProps {
  releaseNote: ReleaseNote
}

export const ReleaseNoteBody = ({ releaseNote }: ReleaseNoteBodyProps): JSX.Element => (
  <>
    <Text className={styles.releaseNoteTitle} font='subtitle-1'>
      {releaseNote.title}
    </Text>
    <Text<'div'> className={styles.releaseNoteBody} font='body-3' as='div'>
      <ReleaseNoteMarkdown releaseNote={releaseNote} />
    </Text>
  </>
)
