export const parseQueryParam = (queryValue?: string | null) => {
  if (!queryValue) return ''
  try {
    return encodeURI(queryValue)
  } catch (error) {
    console.error(error)
  }

  return ''
}
