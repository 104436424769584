export const TIME_RANGE_PARAMETERS = ['start', 'end', 'resolution']

export const TIME_RANGE_VALUES = [
  {
    label: '0 minutes',
    value: '0m',
  },
  {
    label: '10 minutes',
    value: '10m',
  },
  {
    label: '20 minutes',
    value: '20m',
  },
  {
    label: '30 minutes',
    value: '30m',
  },
  {
    label: '1 hour',
    value: '1h',
  },
  {
    label: '2 hours',
    value: '2h',
  },
  {
    label: '3 hours',
    value: '3h',
  },
]

export const RESOLUTION_VALUES = [
  {
    label: '5 minutes',
    value: '5m',
  },
  {
    label: '10 minutes',
    value: '10m',
  },
  {
    label: '20 minutes',
    value: '20m',
  },
  {
    label: '30 minutes',
    value: '30m',
  },
]
