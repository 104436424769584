import type { SnackOptions } from '../../Snack/Snack'
import { ReleaseNoteMarkdown } from './ReleaseNoteMarkdown'
import styles from './releaseNoteModal.module.styl'
import { formattedReleaseDate } from './util'
import { Button, ButtonGroup, Icon, Modal, Text } from '@nike/eds'
import type { ReleaseNote } from 'util/api/pc'

interface ReleaseNoteModalProps {
  releaseNote?: ReleaseNote
  onDismiss: () => void
  techSolutionName: string
  releaseNoteUrl: (releaseNote?: ReleaseNote) => string
  createSnack: (options: SnackOptions) => void
}

/**
 * Displays release note details in a modal, with a button to copy link that opens home page
 * with this modal displayed. This is what users are taken to when they follow a link to an
 * individual release note.
 */
export const ReleaseNoteModal = ({
  releaseNote,
  onDismiss,
  techSolutionName,
  releaseNoteUrl,
  createSnack,
}: ReleaseNoteModalProps) => (
  <Modal
    isOpen={!!releaseNote}
    onDismiss={onDismiss}
    data-testid='view-release-note-modal'
    headerSlot={
      <div>
        {techSolutionName}
        <Text<'p'> font={'title-6'} as={'p'}>
          Release
          <span className='eds-type--body-3 eds-color--secondary eds-spacing--ml-8'>
            {formattedReleaseDate(releaseNote)}
          </span>
        </Text>
      </div>
    }
    footerSlot={
      <ButtonGroup>
        <Button
          type='submit'
          size='small'
          onClick={() => onDismiss()}
          data-testid='view-release-note-model-close'
          data-external-id='view-release-note-model-close'
        >
          Close
        </Button>
        <Button
          size='small'
          variant='secondary'
          beforeSlot={<Icon name='Link' />}
          onClick={async () => {
            await navigator.clipboard.writeText(releaseNoteUrl(releaseNote))
            createSnack({
              id: 'release-note-link-copied',
              status: 'success',
              message: 'Link copied to clipboard',
            })
          }}
          data-external-id='view-release-note-model-copy-link'
        >
          Copy Link
        </Button>
      </ButtonGroup>
    }
  >
    <div className={styles.modalContainer}>
      <Text className={'eds-spacing--mb-16'} font='title-6'>
        {releaseNote?.title}
      </Text>
      <ReleaseNoteMarkdown releaseNote={releaseNote} />
    </div>
  </Modal>
)
