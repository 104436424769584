import { labels } from '../../constants'
import {
  ColorPalette,
  WidgetLifecycleState,
  Widget,
  WidgetExample,
  WidgetLifecycle,
  WidgetSize,
} from '../../types'
import styles from '../../widget.module.styl'
import { ChartOptions, ChartResolver } from '../Charts'
import { isChartData } from '../Charts/utils'
import { Icon, Text } from '@nike/eds'

interface DisabledWidgetProps<T> {
  widget: T & { isFilteredOut?: boolean }
  widgetSize?: WidgetSize
  chartOptions?: ChartOptions
}

const hasExampleData = (val: WidgetExample | undefined | null): val is WidgetExample =>
  val !== undefined && val !== null

const renderDisabledMessage = ({ state, message }: WidgetLifecycle) => {
  let config = {
    title: labels.deprecated.title,
    text: message || labels.deprecated.subtitle,
    icon: 'Alert',
    iconBg: 'var(--eds-color-background-warning)',
    className: '',
  }
  if (state === WidgetLifecycleState.RETIRED) {
    config = {
      ...config,
      title: labels.retired.title,
      text: message || labels.retired.subtitle,
      iconBg: 'var(--eds-color-background-danger)',
      className: styles.retiredIcon,
    }
  }

  return (
    <div className={styles.disabledMessage}>
      <Icon
        name={config.icon}
        size='m'
        backgroundShape='circle'
        backgroundColor={config.iconBg}
        color={'var(--eds-color-white)'}
      />
      <div className={styles.message}>
        <Text font='subtitle-1'>{config.title}</Text>
        <Text font='subtitle-2'>{config.text}</Text>
      </div>
    </div>
  )
}

export const DisabledWidget = <T extends Widget>({
  widget,
  widgetSize = WidgetSize.SMALLSQUARE,
  chartOptions,
}: DisabledWidgetProps<T>) => {
  const example = widget.examples?.[0]
  const widgetData = JSON.parse(example?.data || '{}')

  return (
    <>
      {hasExampleData(example) && isChartData(widgetData) && (
        <ChartResolver
          type={widget.chartType || widgetData.value.type}
          data={widgetData.value.data}
          columns={widgetData.value?.columns}
          additionalClassName={styles.chart}
          colorPalette={widget.colorPalette || ColorPalette.CATEGORICAL}
          options={chartOptions}
          widgetSize={widgetSize}
          isResponsive
        />
      )}
      <div className={styles.overlay}>{renderDisabledMessage(widget.lifecycle)}</div>
    </>
  )
}
