import styles from './calendar.module.styl'

export const Legend = () => (
  <div className={styles.legend}>
    <div>
      <span className={styles.meeting}></span>Meeting
    </div>
    <div>
      <span className={styles.training}></span>Training
    </div>
    <div>
      <span className={styles.release}></span>Release
    </div>
    <div>
      <span className={styles.other}></span>Other
    </div>
  </div>
)
