import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducer.js'
import { subscribeToHistory } from '../navigation/actions.js'

// If we ever do server-side rendering, the initial state needs to check
// for pre-hydrated data, probably in window.__initialState
const initialState = {}

const configureStore = () => {
  let store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  subscribeToHistory(store)

  return store
}

export default configureStore
