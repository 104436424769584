import styles from './accessRequest.styl'
import { TEXT_LABELS } from './consts'
import { Modal, Text } from '@nike/eds'

interface ModalWrapperProps {
  children: JSX.Element | Element
  onDismiss(): void
}

export const ModalWrapper = ({ children, onDismiss }: ModalWrapperProps) => (
  <Modal
    hideFade
    isOpen={true}
    onDismiss={onDismiss}
    headerSlot={<Text<'h3'> as='h3'>{TEXT_LABELS.statusesModal}</Text>}
  >
    <div className={styles.modalBody}>{children}</div>
  </Modal>
)
