import moment from 'moment'

export const formatEventTime = (start: Date, end: Date) => {
  const diffInDays = moment(end).diff(start, 'days')
  const diffInHours = moment(end).diff(start, 'hours')
  const diffInMinutes = moment(end).diff(start, 'minutes')
  const modulo = diffInMinutes % 60

  if (diffInDays) {
    // return the count of the days if the difference is more than 24 hours (that's why we add 1)
    return `${moment(start).format('ll LT')} - ${moment(end).format('ll LT')} (${
      diffInDays + 1
    } days)`
  }

  if (diffInHours) {
    return (
      `${moment(start).format('LT')} - ${moment(end).format('LT')} (${diffInHours} hour${
        diffInHours > 1 ? 's' : ''
      }` + `${modulo ? ', ' + modulo.toString() + ' minutes' : ''})`
    )
  }

  return `${moment(start).format('LT')} - ${moment(end).format('LT')} (${diffInMinutes} minute${
    diffInMinutes > 1 ? 's' : ''
  })`
}
