import { RESOLUTION_VALUES, TIME_RANGE_VALUES } from './consts'
import { CustomFields } from './types'
import { CUSTOM_FIELDS } from './utils'
import { Select } from '@nike/eds'
import { Control, Controller } from 'react-hook-form'

interface TimeRangeFieldProps {
  index: number
  parameter: string
  control: Control<CustomFields>
  fieldName: CUSTOM_FIELDS.supportedRequiredParams | CUSTOM_FIELDS.queryParams
}

export const TimeRangeField = ({ index, parameter, control, fieldName }: TimeRangeFieldProps) => {
  const options = parameter === 'resolution' ? RESOLUTION_VALUES : TIME_RANGE_VALUES
  return (
    <Controller
      name={`${fieldName}.${index}.value`}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Select
          id={`${fieldName}.${index}.value`}
          label='value'
          hideLabel
          data-testid='query-parameter-value'
          options={options}
          value={options.find((item) => item.value === value)}
          onChange={(option) => onChange(option?.value)}
          menuPosition='fixed'
        />
      )}
    />
  )
}
