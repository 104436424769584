export const labels = {
  noDataMsg: 'No widget data available.',
  retired: {
    title: 'Deleted',
    subtitle: 'This widget is no longer available. Please remove it from your dashboard.',
  },
  deprecated: {
    title: 'Inactive',
    subtitle: 'Currently under maintenance. It will be back soon.',
  },
  notValidWidgetData: 'Data not found',
  errorFetchWidgetData: (url: string) => `Error while fetching from URL: ${url}`,
  notValidURL: 'Data source URL is not valid',
  noDataQuery: 'The data query is missing',
  noDataRetry: 'Retry',
  menu: {
    expand: 'Expand widget',
    refresh: 'Refresh widget',
    remove: 'Remove',
    resize: 'Resize',
    share: 'Copy URL',
    editQuery: 'Edit query',
  },
}
